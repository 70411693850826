
module.exports =
  metaInfo:
    title: 'Экономные технологии: тратим меньше на связь и интернет'
    meta: [
      { name: 'description', content: 'Прогрессивная экономия на мобильной связи, интернете и телевидении. Масса эффективных способов, как тратить меньше и при этом всегда быть на связи.' }
      { name: 'keywords', content: 'экономить на мобильной связи, экономить на интернете' }
      { name: 'human_title', content: 'Экономные технологии: тратим меньше на связь и интернет' }
      { name: 'human_subtitle', content: 'Прогрессивная экономия на мобильной связи, интернете и телевидении. Масса эффективных способов, как тратить меньше и при этом всегда быть на связи.' }
    ]
